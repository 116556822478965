// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require('@rails/ujs').start()
// require('turbolinks').start()
// require('@rails/activestorage').start()
require('channels')
require('jquery')
require('libs/jquery.cookie')
require('libs/jquery.textresizer')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// $(document).on('turbolinks:load', function () {
$(function () {
  // 文字サイズ変更
  $('#fontSize a').textresizer({
    // debugMode: true,
    // target: 'body',
    sizes: ['1.25em', '1.125em', '1em']
  })
  $('#fontSize a').css('font-size', '12px')
  $('.idx li').css('font-size', '15px')

  // モバイル画面のメニュー
  $('#mobile_menu').on('click', function (event) {
    event.preventDefault()
    $('#mobile').css('display', 'block')
  })
  $('#mobile a').on('click', function () {
    $('#mobile').css('display', 'none')
  })
  $('#mobile a.close').on('click', function (event) {
    event.preventDefault()
  })

  // 管理画面のフィルタ
  $('#management #filter').on('change', function () {
    window.location = '/management/?filter=' + $('#management #filter').val()
  })

  // インポートが終わるまでの画面保護
  $('#management #import').on('click', function (event) {
    event.preventDefault()
    $('#top').append('<div id="cover"><div class="center-fixed"><div class="status"></div></div></div>')

    const formData = new FormData(document.getElementById('import-csv'))
    const xhr1 = new XMLHttpRequest()
    xhr1.open('POST', '/management/import_csv', true)
    xhr1.onload = function (event) {
      if (xhr1.readyState !== 4) { return }

      if (xhr1.status === 200) {
        const data = JSON.parse(xhr1.responseText)
        const alertMsg = data.alert || ''
        const noticeMsg = data.notice || ''
        window.location = '/management/imported?alert=' + alertMsg + '&notice=' + noticeMsg
        return
      }

      window.location = '/' + xhr1.status
    }
    xhr1.send(formData)

    var xhr = new XMLHttpRequest()
    setInterval(function () {
      xhr.open('GET', '/management/import_processed', false)
      xhr.send(null)
      if (xhr.status !== 200) { return }

      const data = JSON.parse(xhr.responseText)
      const count = parseFloat(data.count)
      const csvCount = parseFloat(data.csv_count)
      const percent = (count / csvCount * 100).toFixed()
      const status = count + ' / ' + csvCount + ' (' + percent + '%)'
      document.querySelector('#cover .status').innerHTML = status
      console.log(status)
    }, 1000)
  })

  // エクスポート後の画面更新
  $('#management #export').on('click', function () {
    var intervalId = setInterval(function () {
      $.get('/management/export_processed', function (data) {
        if (data.exportProcessed) {
          // export済みなのでリダイレクト
          clearInterval(intervalId)
          window.location = '/management'
        }
      })
    }, 1000)
  })

  // Enterキーの無効化
  $('.no-enter').keydown(function (e) {
    if (e.keyCode === 13) {
      return false
    }

    return true
  })

  // プレビュー対応
  var preview = document.getElementById('preview')
  if (preview !== null) {
    var submit = document.getElementById('submit')
    delete submit.dataset.disableWith
    submit.addEventListener('click', function () {
      this.form.target = '' // previewで変更されている場合があるので戻す
    })
    delete preview.dataset.disableWith
    preview.addEventListener('click', function () {
      var f = this.form
      f.target = 'preview'
      var w = window.open('about:blank', f.target)
      w.focus()
    })
  }

/*
  $('.birthday4').keyup(change)
  $('.birthday2').keyup(change)

  function change (e) {
    console.log(e.key)
    if (e.key.length > 1) { return }

    this.value = this.value.replace(/[^\d]/, '')
    if (this.value.length >= this.maxLength) {
      const next = this.nextElementSibling
      if (next) { next.focus() }
    }
  }
*/

  window.onunload = function () {
    // console.log('unload')
  }
  // console.log('set unload')
})
